export const hash = 'deb763c7-088b-43e6-93f4-13f2dad8f81f';

// export const gateKey = '';
export const gateKey = 'ek_live_g5Df6bp6y5ptAKeOVOpBloZHmqY0sb';
export const recaptchaKey = '6Ld9r7cZAAAAABw5wijiLD5PhlWqLivIN9NqFKi_';
export const apiUrl = `https://api-global-v3.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://socket-2023.encontrodigital.com.br/';
//export const apiSocket = 'http://localhost:8001';

//export const apiUrl = `http://localhost:8000/${hash}`;
export const defaultProjectHash = '';
export const urlWebsite = 'https://www.conectasaudesesi.com.br';
export const supportNumber = `555195181533`;
export const home = 'Home';
export const haveTranslate = false;
// export const urlWebsite = 'http://localhost:3000';
export const eventName = 'Sesi Conecta Saúde';
export const logo = '/assets/logo2.png';
export const background = '/assets/evento/Fundo.jpeg';
export const authTitle = 'Sesi Conecta Saúde';
export const dadosOrganizacao = '';
export const social = {
  whatsapp: '',
  instagram: '',
  facebook: '',
  linkedin: '',
  youtube: '',
  twitter: '',
  website: ''
};


export const defaultTheme = {
  defaultBackground: 'rgb(0,14,64)',
  defaultColor: '#fff',
  logo: {
    minWidth: '450px',
  },
  menu: {
    background: 'none',
    backgroundSticky: 'none',
    backgroundMobile: 'rgb(0,14,64)',
    color: '#fff',
    shadow: '0',
    logoColor: 'none',
  },
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(`@${authTitle}:token`);
  return token;
};


